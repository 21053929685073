import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './SignIn';
import { useAuth } from './AuthContext';
import Search from './Search';
import Favourites from './Favourites';
import Home from './Home';
import { SearchProvider } from './SearchContext';
import './App.css';

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/" />;
};

const PublicRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated ? element : <Navigate to="/home/search" />;
};


function App() {
  const { loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <SearchProvider>
      <Router>
        <Routes>
          <Route path="/home" element={<Home />}>
            <Route path="/home/search" element={<Search />} />
            <Route path="/home/favourites" element={<PrivateRoute element={<Favourites />} />} />
          </Route>
          <Route path="/" element={<PublicRoute element={<SignIn />} />} />
        </Routes>
      </Router>
    </SearchProvider>
  );
}

export default App;