import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <footer>
            <a target="_blank" href="https://www.iubenda.com/privacy-policy/12575445" rel="noreferrer" class="iubenda-white iubenda-embed" title="Privacy Policy ">Privacy Policy</a>
            <span> | </span>
            <a target="_blank" href="https://www.iubenda.com/terms-and-conditions/12575445" rel="noreferrer" class="iubenda-white iubenda-embed" title="Privacy Policy ">Terms and Conditions</a>
        </footer>
    );
};

export default Footer;