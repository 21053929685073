import React, { useState } from 'react';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { auth, provider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import SignInModal from './SignInModal';
import { useSearch } from './SearchContext';
import { ReactComponent as Logo } from './components/stylewiz_header_logo-cropped.svg';
import './Home.css';

const Home = () => {

    const { isAuthenticated } = useAuth();  // Ensure userId is available
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { input, selectedFile, priceRange, selectedFabrics, result, searchAttempted, updateSearchState } = useSearch();

    const handleSignOut = () => {
        auth.signOut().then(function () {
            updateSearchState('', null, [0, 1000], [], [], false);
            navigate('/');
        }, function (error) {
            console.error('Sign Out Error', error);
        });
    };

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                navigate('/home/search');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleFavouritesClick = () => {
        if (!isAuthenticated) {
            setIsModalOpen(true);
        } else {
            updateSearchState(input, selectedFile, priceRange, selectedFabrics, result, searchAttempted);
            navigate('/home/favourites');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="home-container">
            <header>
                <div className='left-nav'>
                    <Logo className='logo' />
                </div>
                <div className='right-nav'>
                    <Link className="header-link" to="/home/search">Search</Link>
                    <button className="header-button" onClick={handleFavouritesClick}>My Favourites</button>
                    {isAuthenticated && <button className="sign-out" onClick={handleSignOut}>Sign Out</button>}
                    {!isAuthenticated && <button className="sign-in" onClick={signInWithGoogle}>Sign In</button>}
                </div>
            </header>
            <Outlet />
            <SignInModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>
    );
}

export default Home;