import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import { ReactComponent as Logo } from './components/stylewiz_logo.svg';
import Footer from './Footer';
import './SignIn.css';

function SignIn() {
    const navigate = useNavigate();
    const [displayText, setDisplayText] = useState("");
    const [typingIndex, setTypingIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const texts = useMemo(() => ["I'm looking for a green floral dress for Italy", "I'm searching for a gray suit for an interview", "I need a red sleeveless dress for date night"], []);

    useEffect(() => {
        const handleTyping = () => {
            if (isDeleting) {
                if (charIndex > 0) {
                    setDisplayText((prev) => prev.slice(0, -1));
                    setCharIndex((prev) => prev - 1);
                } else {
                    setIsDeleting(false);
                    setTypingIndex((prev) => (prev + 1) % texts.length);
                }
            } else {
                if (charIndex < texts[typingIndex].length) {
                    setDisplayText((prev) => prev + texts[typingIndex].charAt(charIndex));
                    setCharIndex((prev) => prev + 1);
                } else {
                    setTimeout(() => setIsDeleting(true), 1500);
                }
            }
        };

        const typingSpeed = isDeleting ? 25 : 50;
        const typingTimeout = setTimeout(handleTyping, typingSpeed);

        return () => clearTimeout(typingTimeout);
    }, [charIndex, isDeleting, texts, typingIndex]);

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                navigate('/home/search');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className='main-container'>
            <a target="_blank" href="https://wj9hsw2hlos.typeform.com/to/yRfd1dWB" rel="noreferrer" class="become-a-partner" title="Partner with Us">Partner with Us</a>
            <div className="signin-container">
                <Logo style={{ alignSelf: 'center', height: '450px' }} />
                <div className="search-bar">
                    <div className="typewriter-text">
                        <span className="typing">{displayText}</span>
                    </div>
                </div>
                <button className="try-now" onClick={() => navigate('/home/search')}>Try Now</button>
                <p className='or-option'>- or -</p>
                <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
                <Footer />
            </div>
        </div>
    );
}

export default SignIn;