import React from 'react';
import Modal from 'react-modal';
import './UploadFileModal.css';

const UploadFileModal = ({ isOpen, handleFileChange, handleModalSearch, closeModal }) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Upload by Image"
            className="modal"
            overlayClassName="overlay"
            appElement={document.getElementById('root')}
        >
            <h2>Upload an Image</h2>
            <input type="file" onChange={handleFileChange} />
            <button className="file-search" onClick={handleModalSearch}>Search</button>
            <button className="cancel-button" onClick={closeModal}>Cancel</button>
        </Modal>
    );
}

export default UploadFileModal;