// src/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from './firebase';

// Create the Auth Context
const AuthContext = createContext();

// Auth Provider component
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setIsAuthenticated(!!user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const refreshAuthToken = async () => {
        try {
            const user = auth.currentUser;
            if (user) {
                const newToken = await user.getIdToken(true); // Force refresh
                return newToken;
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
        }
        return null;
    };

    return (
        <AuthContext.Provider value={{ refreshAuthToken, isAuthenticated, currentUser, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the Auth Context
export const useAuth = () => {
    return useContext(AuthContext);
};