import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { auth, provider } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './SignInModal.css';

const SignInModal = ({ isOpen, isTrial, isFavourite, onRequestClose }) => {

    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                navigate('/home/search');
                onRequestClose()
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (isAuthenticated) {
        return null;
    }
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Sign In Modal"
            className="modal"
            overlayClassName="overlay"
        >
            <button className="close-button" onClick={onRequestClose}><FontAwesomeIcon icon={faTimes} /></button>
            <h2>Sign In Required</h2>
            {isTrial && <p>Looks like you're enjoying our search feature! 😊</p>}
            {isTrial && <p>Please sign in to get the full experience and continue your search.</p>}
            {isFavourite && <p>Get notified about discounts by favouriting items! 😊</p>}
            {!isTrial && <p>Please sign in to access the full set of features.</p>}
            <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
        </Modal>
    );
}

export default SignInModal;