import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Search.css'; // Import CSS file for styling
import { useAuth } from './AuthContext';
import axios from 'axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { ClipLoader } from 'react-spinners';
import SignInModal from './SignInModal';
import { useSearch } from './SearchContext';
import UploadFileModal from './UploadFileModal';

const fabricsOptions = ['cashmere', 'cotton', 'satin', 'silk', 'leather', 'linen', 'polyester', 'nylon', 'wool'];

const Search = () => {
    const { currentUser, isAuthenticated } = useAuth();
    const { input, selectedFile, priceRange, selectedFabrics, result, searchAttempted, setInput, setSelectedFile, setPriceRange, setSelectedFabrics, setResult, setSearchAttempted } = useSearch();
    const [visualPriceRange, setVisualPriceRange] = useState(priceRange);
    const [favourites, setFavourites] = useState({});
    const [loading, setLoading] = useState(false);
    const [signInModalOpen, setSignInModalOpen] = useState(false);
    const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [isFavourite, setIsFavourite] = useState(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const priceRangeRef = useRef(priceRange);
    const selectedFabricsRef = useRef(selectedFabrics);

    useEffect(() => {
        const fetchFavourites = async () => {
            const token = await currentUser.getIdToken();
            try {
                const response = await axios.get('/api/favourites', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                const favourites = response.data.favourites;
                const favMap = favourites.reduce((acc, favourite) => {
                    acc[favourite.url] = true;
                    return acc;
                }, {});
                setFavourites(favMap);
            } catch (error) {
                console.error('Error fetching favorites:', error);
            }
        };
        if (currentUser) {
            fetchFavourites();
        }
    }, [currentUser]);

    const performSearch = useCallback(async () => {
        if (input.trim() === '' && selectedFile === null) return;

        const formData = new FormData();
        formData.append('user_input', input);
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        if (priceRange) {
            formData.append('price_min', priceRange[0]);
            formData.append('price_max', priceRange[1]);
        }
        formData.append('fabrics', JSON.stringify(selectedFabrics));

        setLoading(true); // Set loading to true before the request

        try {
            const headers = { 'Content-Type': 'multipart/form-data', 'Cache-Control': 'no-cache' };
            if (currentUser) {
                const token = await currentUser.getIdToken();
                headers.Authorization = `Bearer ${token}`;
            }
            const response = await axios.post('/api/search', formData, {
                headers
            });
            setResult(response.data.result);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setLoading(false); // Set loading to false after the request completes
            setSearchAttempted(true);
        }
    }, [input, selectedFile, priceRange, selectedFabrics, currentUser, setSearchAttempted, setResult]);

    const searchQuery = useCallback(async () => {
        if (isAuthenticated) {
            performSearch();
        }
        else {
            const searchAttemptCount = localStorage.getItem('c') || 0;
            const searchAttemptLimit = 3;
            if (!input && !selectedFile) return;
            if ((searchAttemptCount < searchAttemptLimit)) {
                performSearch();
                localStorage.setItem('c', Number(searchAttemptCount) + 1);
            }
            else {
                setIsTrial(true);
                setIsFavourite(false);
                setSignInModalOpen(true);
            }
        }
    }, [isAuthenticated, performSearch, input, selectedFile]);

    useEffect(() => {
        if (
            priceRangeRef.current !== priceRange ||
            selectedFabricsRef.current !== selectedFabrics
        ) {
            searchQuery();
            priceRangeRef.current = priceRange;
            selectedFabricsRef.current = selectedFabrics;
        }
    }, [priceRange, selectedFabrics, searchQuery]);


    const handleKeyDown = async (event) => {
        setSelectedFile(null);
        if (event.key === 'Enter') {
            searchQuery();
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleModalSearch = () => {
        setInput('');
        setUploadFileModalOpen(false);
        searchQuery();
    }

    const handleSearchButtonClick = () => {
        setSelectedFile(null);
        searchQuery();
    }

    const handleFavourite = async (item) => {
        if (!isAuthenticated) {
            setIsTrial(false);
            setIsFavourite(true);
            setSignInModalOpen(true);
            return;
        }
        try {
            const token = await currentUser.getIdToken();
            if (favourites[item.url]) {
                await axios.delete('/api/favourite', {
                    data: { item },
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
            } else {
                await axios.post(
                    '/api/favourite',
                    { item },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    }
                );
            }
            setFavourites((prevFavourites) => ({
                ...prevFavourites,
                [item.url]: !prevFavourites[item.url],
            }));
        } catch (error) {
            console.error('Error updating favourite:', error);
        }
    };

    const handleFabricChange = (fabric) => {
        setSelectedFabrics((prevSelectedFabrics) => {
            const updatedFabrics = prevSelectedFabrics.includes(fabric)
                ? prevSelectedFabrics.filter((f) => f !== fabric)
                : [...prevSelectedFabrics, fabric];
            return updatedFabrics;
        });
    };

    const handleUploadFileModalOpen = () => {
        setUploadFileModalOpen(true);
    };

    const constructUrlForItem = (itemURL) => {
        const url = new URL(itemURL);
        url.searchParams.set('utm_source', 'stylewiz.ai');
        return url.toString();
    }

    return (
        <div className="search-page">
            <div className={`left-panel ${isPanelOpen ? 'open' : ''}`}>
                <div className="filter-container">
                    <div className="slider-box">
                        <p><b>Your Budget</b></p>
                        <p>${priceRange[0]} - ${priceRange[1]}</p>
                        <Slider
                            range
                            min={0}
                            max={1000}
                            defaultValue={[0, 1000]}
                            value={visualPriceRange}
                            onChange={setVisualPriceRange}
                            onChangeComplete={setPriceRange}
                        />
                    </div>
                    <div className="fabrics-box">
                        <p><b>Fabrics</b></p>
                        <div className="fabrics-options">
                            {fabricsOptions.map((fabric) => (
                                <div
                                    key={fabric}
                                    className={`fabric-tag ${selectedFabrics.includes(fabric) ? 'selected' : ''}`}
                                    onClick={() => handleFabricChange(fabric)}
                                >
                                    {fabric}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="hamburger-menu" onClick={() => setIsPanelOpen(!isPanelOpen)}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <div className="search-bar-container">
                    <div className="search-section">
                        <input
                            type="text"
                            placeholder="Search an outfit..."
                            className="search-bar"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button className="search-button" onClick={handleSearchButtonClick}>Search</button>
                    </div>
                    <p className="button-divider">or</p>
                    <button className="upload-button" onClick={handleUploadFileModalOpen}>Upload Image</button>
                </div>
                <div className="results-container">
                    {loading ? (
                        <div className="spinner-container">
                            <ClipLoader size={50} color={"#fff"} loading={loading} />
                        </div>
                    ) : (
                        searchAttempted && result.length === 0 ? (
                            <p>No results found</p>
                        ) : (
                            <div className="grid">
                                {result.map((item, index) => (
                                    <div className="grid-item" key={index}>
                                        <a
                                            href={constructUrlForItem(item.url)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="grid-item-link"
                                        >
                                            <img src={item.image_url} alt={item.title} className="item-image" />
                                            <h3 className="item-title">{item.title}</h3>
                                            <p className="item-brand">{item.brand}</p>
                                            <div className="item-price-container">
                                                {item.regular_price ? <p className="item-regular-price">${item.regular_price % 1 === 0 ? item.regular_price : item.regular_price.toFixed(2)}</p> : null}
                                                <p className="item-price">${item.price % 1 === 0 ? item.price : item.price.toFixed(2)}</p>
                                            </div>
                                        </a>
                                        <button
                                            className={`favourite-button ${favourites[item.url] ? 'favourited' : ''}`}
                                            onClick={() => handleFavourite(item)}
                                        >
                                            {favourites[item.url] ? '❤️' : '♡'}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </div>
            </div>
            <UploadFileModal isOpen={uploadFileModalOpen} handleFileChange={handleFileChange} handleModalSearch={handleModalSearch} closeModal={() => setUploadFileModalOpen(false)} />
            <SignInModal isOpen={signInModalOpen} onRequestClose={() => setSignInModalOpen(false)} isTrial={isTrial} isFavourite={isFavourite} />
        </div>
    );
};

export default Search;