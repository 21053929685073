import React, { createContext, useState, useContext } from 'react';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
    const [input, setInput] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [priceRange, setPriceRange] = useState([0, 1000]);
    const [selectedFabrics, setSelectedFabrics] = useState([]);
    const [result, setResult] = useState([]);
    const [searchAttempted, setSearchAttempted] = useState(false);

    const updateSearchState = (newInput, newSelectedFile, newPriceRange, newSelectedFabrics, newResult, newSearchAttempted) => {
        setInput(newInput);
        setSelectedFile(newSelectedFile);
        setPriceRange(newPriceRange);
        setSelectedFabrics(newSelectedFabrics);
        setResult(newResult);
        setSearchAttempted(newSearchAttempted);
    };

    return (
        <SearchContext.Provider value={{ input, selectedFile, priceRange, selectedFabrics, result, searchAttempted, setInput, setSelectedFile, setPriceRange, setSelectedFabrics, setResult, setSearchAttempted, updateSearchState }}>
            {children}
        </SearchContext.Provider>
    );
};