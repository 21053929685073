import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import './Favourites.css';
import { ClipLoader } from 'react-spinners';

const Favourites = () => {
    const [favourites, setFavourites] = useState([]);
    const [result, setResult] = useState([]);
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchFavourites = async () => {
            setLoading(true);
            try {
                const token = await currentUser.getIdToken();
                const response = await axios.get('/api/favourites', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                const favourites = response.data.favourites;
                setResult(favourites);
                const favMap = favourites.reduce((acc, favourite) => {
                    acc[favourite.url] = true;
                    return acc;
                }, {});
                setFavourites(favMap);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching favourites:', error);
            }
        };
        if (currentUser) {
            fetchFavourites();
        }
    }, [currentUser]);

    const handleFavourite = async (item) => {
        const token = await currentUser.getIdToken();
        try {
            if (favourites[item.url]) {
                await axios.delete('/api/favourite', {
                    data: { item },
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
            } else {
                await axios.post(
                    '/api/favourite',
                    { item },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    }
                );
            }
            setFavourites((prevFavourites) => ({
                ...prevFavourites,
                [item.url]: !prevFavourites[item.url],
            }));
        } catch (error) {
            console.error('Error updating favourite:', error);
        }
    };

    return (
        <div className="favourites-container">
            <h1>Your Favourites</h1>
            {loading ? (
                <div className="spinner-container">
                    <ClipLoader size={50} color={"#fff"} loading={loading} /></div>
            ) : (
                <div className="grid">
                    {
                        result.length === 0 ? (
                            <p>No favourites found</p>
                        ) : (
                            result.map((item, index) => (
                                <div className="grid-item" key={index}>
                                    <a href={item.url} target="_blank" rel="noopener noreferrer" className="grid-item-link">
                                        <img src={item.image_url} alt={`Favourite ${index + 1}`} className="item-image" />
                                        <h3 className="item-title">{item.title}</h3>
                                        <p className="item-brand">{item.brand}</p>
                                        <p className="item-price">${item.price}</p>
                                    </a>
                                    <button
                                        className={`favourite-button ${favourites[item.url] ? 'favourited' : ''}`}
                                        onClick={() => handleFavourite(item)}
                                    >
                                        {favourites[item.url] ? '❤️' : '♡'}
                                    </button>
                                </div>
                            ))
                        )}
                </div>)}
        </div>
    );
};

export default Favourites;